/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import moment from 'moment';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';

import { DEFAULT_LOCALE, LOCAL_STORAGE_LOCALE_KEY } from 'App/constants';

// export type Locales = 'en' | 'fi' | 'et' | 'swe';
export enum Locales {
  en = 'en',
  fi = 'fi',
  et = 'et',
  se = 'se',
}

export const appLocales = [Locales.en, Locales.fi, Locales.et, Locales.se];

const extractLanguageKeyFromLocalStorage = (): Locales => {
  if (process.env.NODE_ENV === 'test') {
    return DEFAULT_LOCALE as Locales;
  }
  try {
    return (localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) || DEFAULT_LOCALE) as Locales;
  } catch (error) {
    return DEFAULT_LOCALE as Locales;
  }
};

const fromLocalStorage = extractLanguageKeyFromLocalStorage();
export const storedLocale = appLocales.includes(fromLocalStorage) ? fromLocalStorage : DEFAULT_LOCALE;

moment.locale(storedLocale);

export function importMessages(locale: Locales): Promise<Record<string, any>> {
  switch (locale) {
    case Locales.fi:
      return import('./translations/fi');
    case Locales.et:
      return import('./translations/et');
    case Locales.se:
      return import('./translations/swe');
    case Locales.en:
    default:
      return import('./translations/en');
  }
}

export async function switchLocale(locale: Locales) {
  localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale);
  await momentLocale(locale);
  moment.locale(locale);

  await polyfillPolyfillPluralRules(locale);
  await polyfillRelativeTimeFormat(locale);
}

export async function momentLocale(locale: Locales) {
  if (locale === 'en') {
    return;
  }
  // bypass, because in moment se is Sami and sv is Swedish
  if (locale === Locales.se) {
    // @ts-expect-error moment typings are wrong
    await import('moment/locale/sv');
    return;
  }
  await import(`moment/locale/${locale}`);
}

export async function polyfillIntlLocale() {
  // This platform already supports Intl.Locale
  if (shouldPolyfillIntlLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }
}

export async function polyfillRelativeTimeFormat(locale: Locales) {
  if (!shouldPolyfillRelativeTimeFormat()) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-relativetimeformat/polyfill');

  switch (locale) {
    case Locales.fi:
      await import('@formatjs/intl-relativetimeformat/locale-data/fi');
      break;
    case Locales.et:
      await import('@formatjs/intl-relativetimeformat/locale-data/et');
      break;
    case Locales.se:
      await import('@formatjs/intl-relativetimeformat/locale-data/se');
      break;
    default:
      await import('@formatjs/intl-relativetimeformat/locale-data/en');
      break;
  }
}

export async function polyfillPolyfillPluralRules(locale: Locales) {
  if (!shouldPolyfillPluralRules()) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-pluralrules/polyfill');

  switch (locale) {
    default:
      await import('@formatjs/intl-pluralrules/locale-data/en');
      break;
    case Locales.fi:
      await import('@formatjs/intl-pluralrules/locale-data/fi');
      break;
    case Locales.et:
      await import('@formatjs/intl-pluralrules/locale-data/et');
      break;
    case Locales.se:
      await import('@formatjs/intl-pluralrules/locale-data/se');
      break;
  }
}
