/* eslint-disable react/jsx-props-no-spreading,@typescript-eslint/no-explicit-any */
import ReactSelect, { ActionMeta, OptionTypeBase, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect, { Props as RSProps } from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import sortBy from 'lodash/sortBy';

import { actionHandler, getSelectValue, handleSelectChange } from 'common/form/utils';
import {
  GetSelectOptionLabel,
  GetSelectOptionValue,
  SelectSelectedOptions,
  SelectSelectedValue,
} from 'common/form/types';
import { customStyles, domaTheme } from 'common/form/plain/SelectStyle';
import { ClearIndicator, DropdownIndicator, MultiValue, MenuList } from 'common/form/plain/SelectComponents';

import commonMessages from 'translations/CommonTranslations/messages';

export type Props<IsMulti extends boolean, OnlyValue extends boolean, Option extends OptionTypeBase = any> = {
  disabled?: boolean;
  onlyValue?: boolean;
  onChange: (option: SelectSelectedValue<IsMulti, OnlyValue, Option>) => void;
  error?: boolean;
  value: SelectSelectedValue<IsMulti, OnlyValue, Option> | null;
  isMulti?: boolean;
  isCreatable?: boolean;
  isVirtualized?: boolean;
  isAllSelectable?: boolean;
  // async select
  loadOptions?: (
    inputValue: string,
    callback?: (options: ReadonlyArray<Option>) => void,
  ) => Promise<ReadonlyArray<Option>> | void;
  defaultOptions?: ReadonlyArray<Option> | boolean;
  closeMenuOnSelect?: boolean;
  getOptionLabel?: GetSelectOptionLabel<Option>;
  getOptionValue?: GetSelectOptionValue<Option>;
  testId?: string;
  transparentBg?: boolean;
  isCompactMulti?: boolean;
  sort?: (option: Record<string | number, any>) => Option;
} & Pick<
  RSProps<Option, IsMulti>,
  | 'name'
  | 'inputId'
  | 'placeholder'
  | 'className'
  | 'isLoading'
  | 'isClearable'
  | 'options'
  | 'cacheOptions'
  | 'onInputChange'
  | 'onBlur'
  | 'components'
>;
/**
 * Select can have single value or multiple values `isMulti` prop
 *
 *
 * ## Creatable
 * use `isCreatable` prop
 *
 * utils for Creatable Select `common/form/utils`
 *
 * - `removeNewOptionsSelect`  It will remove options that were created (they have `__isNew__` property)
 * - `retrieveNewOptionsSelect`  It will retrieve new options but without `__isNew__` property
 *
 * check also https://react-select.com/creatable
 *
 * ## Async
 * use `loadOptions` callback prop, If you want use async options (e.g. REST API)
 * use together with defaultOptions and cacheOptions
 *
 * - callback should return Promise (recommended)
 *
 * check also https://react-select.com/async
 *
 * ## On Change
 * `onChange` returns `Option Object` or `value`, if it's used together with `onlyValue`.
 * When used with `isCreatable`, new option will contain `__isNew__: true`.
 */
const Select = <IsMulti extends boolean, OnlyValue extends boolean, Option extends OptionTypeBase = OptionTypeBase>({
  name,
  value,
  onlyValue = false,
  isMulti = false,
  options: extOptions,
  onChange,
  onBlur,
  placeholder,
  disabled,
  getOptionLabel,
  getOptionValue,
  isLoading,
  isClearable,
  isCreatable,
  isVirtualized,
  isAllSelectable,
  loadOptions,
  cacheOptions,
  defaultOptions,
  closeMenuOnSelect,
  error,
  components,
  testId,
  inputId,
  transparentBg,
  isCompactMulti,
  sort,
}: Props<IsMulti, OnlyValue, Option>) => {
  const [showAll, setShowAll] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const options = sort ? sortBy(extOptions, sort) : extOptions;
  const [lOptions, setLOptions] = useState<Option[]>(() => {
    if (loadOptions && Array.isArray(defaultOptions)) {
      return defaultOptions;
    }
    if (options) {
      return options as Option[];
    }
    return [];
  });

  /*
   * This is for the case when user select the Select all option and then reset
   * the form. The selectAll need to be reset manually in that situation.
   */
  useEffect(() => {
    if (Array.isArray(value) && !value?.length && selectAll) {
      setSelectAll(false);
    }
  }, [value, selectAll]);

  /*
  This is for when we change the Select options programmatically, and selectAll was selected before that change.
   */
  useEffect(() => {
    setSelectAll(false);
  }, [options]);

  const getValue = () => {
    if (onlyValue) {
      return getSelectValue((options || lOptions) as Option[], value, isMulti, getOptionValue);
    }
    return value;
  };

  const toggleShowAll = () => {
    /* No need to open the menu when user clicks on expand and contract selected menu items options */
    setMenuIsOpen(false);
    setShowAll(!showAll);
  };
  const toggleSelectAll = () => {
    /* This is to close the menu when user selects all */
    if (!selectAll) {
      setMenuIsOpen(false);
    }
    setSelectAll(!selectAll);
  };

  const handleChange = (option: SelectSelectedOptions<typeof isMulti>, action: ActionMeta<Option>) => {
    const actionOptions = actionHandler(
      option,
      action,
      isAllSelectable,
      options as any,
      showAll,
      isCompactMulti,
      toggleShowAll,
      toggleSelectAll,
      selectAll,
    );

    if (!option || !actionOptions) {
      return onChange(isMulti ? [] : (null as any));
    }

    if (onlyValue) {
      return onChange(handleSelectChange(actionOptions, isMulti, getOptionValue) as any);
    }

    return onChange(actionOptions as any);
  };

  // When user inputs a value, this function gets the data based on that
  const handleLoadOptions = async (inputValue: string): Promise<ReadonlyArray<Option>> => {
    if (loadOptions) {
      const data = await loadOptions(inputValue);
      setLOptions(data as Option[]);
      return data as Option[];
    }
    return [];
  };

  const commonProps: Partial<RSProps<Option, IsMulti>> = {
    theme: domaTheme,
    menuPortalTarget: document.body,
    components: { DropdownIndicator, ClearIndicator, ...components },
    value: getValue() as Option,
    onChange: handleChange,
    isMulti: isMulti as IsMulti,
    isDisabled: disabled,
    styles: customStyles(transparentBg) as StylesConfig<Option, IsMulti>,
    menuPlacement: 'auto',
    closeMenuOnSelect: closeMenuOnSelect !== undefined ? closeMenuOnSelect : !isMulti,
    getOptionLabel: getOptionLabel as any, // we return number and string, but original is only string
    getOptionValue: getOptionValue as any, // we return number and string, but original is only string
    placeholder: placeholder || `${formatMessage(commonMessages.select)}...`,
    isLoading,
    isClearable,
    onBlur,
    error,
    name,
    inputId,
    instanceId: testId || name,
  };

  /* If the options list is more than 10, use virtual list. One of the reason for this is that we have
     to give maxHeight to virtual component which even stays at the same height when there is only few options
     in the Select
   */
  const requiredOptionsLengthForVirtual = 10;
  if (options && options.length > requiredOptionsLengthForVirtual && isVirtualized) {
    commonProps.components = { ...commonProps.components, MenuList };
  }

  // when we have the options, then create the options list with selectAll option
  const optionsWithSelectAll = options && [
    {
      id: null,
      name: formatMessage(commonMessages.selectAll),
    },
    ...options,
  ];
  const selectedOptions = isAllSelectable && options?.length && options?.length > 1 ? optionsWithSelectAll : options;

  // For the select is async and options are creatable
  if (loadOptions && isCreatable) {
    return (
      <AsyncCreatableSelect
        {...commonProps}
        cacheOptions={cacheOptions}
        loadOptions={handleLoadOptions}
        defaultOptions={defaultOptions}
        isCreatable
      />
    );
  }

  // For the async select
  if (loadOptions) {
    return (
      <AsyncSelect
        {...commonProps}
        cacheOptions={cacheOptions}
        loadOptions={handleLoadOptions}
        defaultOptions={defaultOptions}
      />
    );
  }

  // For the select options are creatable
  if (isCreatable) {
    return <CreatableSelect {...commonProps} options={selectedOptions as any} />;
  }

  if (isCompactMulti) {
    return (
      <ReactSelect
        {...commonProps}
        options={selectedOptions as any}
        components={{ ...commonProps.components, MultiValue }}
        toggleShowAll={toggleShowAll}
        showAll={showAll}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        menuIsOpen={menuIsOpen}
        filterOption={(option: any, inputValue: string) => {
          if (!selectAll && !inputValue) {
            return true;
          }

          return !!(inputValue && option.label.toLowerCase().includes(inputValue));
        }} // When user selects the "select all" option, we don't need to show any option including the "select all"
      />
    );
  }

  return <ReactSelect {...commonProps} options={selectedOptions as any} />;
};
export default Select;
