/* eslint-disable */
/**
 * @description https://github.com/darkskyapp/string-hash/issues/12
 * @param str
 */
export default function hash(str: string) {
  let hash = 5381;
  const len = str.length;

  for (let i = 0; i < len; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i);
  }

  return hash >>> 0;
}

// Generate stable hash from username and password
export async function generateIdentifierHash(username: string, password: string) {
  const data = new TextEncoder().encode(username + password);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
